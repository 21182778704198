import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  CardMedia,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Collapse,
  Button,
  IconButton,
  TextField,
  Link as LinkMui,
  List,
  ListItem,
} from "@mui/material";
import { Link } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Autocomplete from "@mui/material/Autocomplete";
import { useAuth } from "../contexts/AuthContext";
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  updateDoc,
  arrayRemove,
} from "firebase/firestore";

function Mix() {
  const [openRows, setOpenRows] = useState({});
  const [tournaments, setTournaments] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [loading, setLoading] = useState(true);
  const { user } = useAuth(); // Use authentication context to check if a user is logged in

  useEffect(() => {
    const fetchTournaments = async () => {
      const db = getFirestore();
      const mixTournamentsSnapshot = await getDocs(
        collection(db, "mix_tournaments")
      );
      const tournamentsList = mixTournamentsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      tournamentsList.sort((a, b) => new Date(a.date) - new Date(b.date));

      setTournaments(tournamentsList);
    };

    const fetchUsers = async () => {
      const db = getFirestore();
      const usersSnapshot = await getDocs(collection(db, "users"));
      const usersList = usersSnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          displayName: data.displayName,
          surname: data.surname,
          gender: data.gender || "undefined",
          mixPoints: data.mixPoints || 0,
          birthYear: doc.data().birthDate
            ? doc.data().birthDate.substring(0, 4)
            : "Unknown",
          height: doc.data().height,
        };
      });
      setUsers(usersList);
      setLoading(false);
    };

    fetchTournaments();
    fetchUsers();
  }, []);

  const handleExpandClick = (index) => {
    setOpenRows((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const handleRegisterTeam = async (tournamentId, currentCapacity, teams) => {
    if (!user || !selectedUser[tournamentId]?.id) {
      alert("Please select a second player.");
      return;
    }

    const player1Points = users.find((u) => u.id === user.uid)?.mixPoints || 0;
    const player2Points =
      users.find((u) => u.id === selectedUser[tournamentId].id)?.mixPoints || 0;

    const team = {
      player1: user.uid,
      player2: selectedUser[tournamentId].id,
      teamPoints: player1Points + player2Points,
      registeredAt: new Date(),
      place: 0,
    };

    const allTeams = [...(teams || []), team].sort(
      (a, b) => b.teamPoints - a.teamPoints || a.registeredAt - b.registeredAt
    );

    const db = getFirestore();
    const tournamentRef = doc(db, "mix_tournaments", tournamentId);

    await updateDoc(tournamentRef, {
      teams: allTeams,
    });

    alert("Team has been successfully registered.");
    setSelectedUser({});
    window.location.reload(); // Refresh the page to show updated status
  };

  const handleUnregisterTeam = async (tournamentId, registeredTeam) => {
    const db = getFirestore();
    const tournamentRef = doc(db, "mix_tournaments", tournamentId);

    await updateDoc(tournamentRef, {
      teams: arrayRemove(registeredTeam),
    });

    alert("You have been unregistered from the tournament.");
    window.location.reload(); // Refresh the page to show updated status
  };

  const isUserInTournament = (teams) => {
    return teams.some(
      (team) => team.player1 === user?.uid || team.player2 === user?.uid
    );
  };

  return (
    <Box
      sx={{
        backgroundColor: "#F5F5F5",
        color: "#333333",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Navbar />
      <Box
        sx={{
          textAlign: "center",
          padding: "20px",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h3"
          component="h1"
          gutterBottom
          sx={{
            color: "#FF5722",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
          }}
        >
          Mr. Mango mixy o 10 000,-
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: "20px",
            alignItems: "center",
            marginTop: "20px",
            maxWidth: "1200px",
          }}
        >
          <Box sx={{ textAlign: "left", maxWidth: "600px" }}>
            <Typography variant="h5" component="div" sx={{ mb: 2 }}>
              Informace
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Přichází zima a s ní je tady další série mixových turnajů o
              nějakou tu kačku. Přijď si zahrát kvalitní beach volejbal a
              shrábnout výhru, která čeká nejen na první 3 týmy!
            </Typography>
            <Typography variant="body1">
              Každý turnaj hrajeme o 10 000,- které se budou dělit následovně:
            </Typography>
            <List sx={{ mb: 4 }}>
              <ListItem sx={{ paddingY: 0.5 }}>1. 3500,-</ListItem>
              <ListItem sx={{ paddingY: 0.5 }}>2. 2000,-</ListItem>
              <ListItem sx={{ paddingY: 0.5 }}>3. 2000,-</ListItem>
              <ListItem sx={{ paddingY: 0.5 }}>4. 1000,-</ListItem>
              <ListItem sx={{ paddingY: 0.5 }}>5. 1000,- (12+ týmů)</ListItem>
              <ListItem sx={{ paddingY: 0.5 }}>9. 500,- (12+ týmů)</ListItem>
            </List>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              Zároveň hrajeme o ceny za umístění (jednotlivce) v celé sérii!
            </Typography>
            <List sx={{ fontWeight: "bold" }}>
              <ListItem sx={{ paddingY: 0.5 }}>1. 20 000,-</ListItem>
              <ListItem sx={{ paddingY: 0.5 }}>2. 10 000,-</ListItem>
              <ListItem sx={{ paddingY: 0.5 }}>3. 5 000,-</ListItem>
            </List>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              Počítají se 2 nejlepší výsledky a v případě shody se bude počítat
              3/4 nejlepší výsledek. V případě, že bude shoda i potom, tak si to
              adepti rozdají v bagrované.
            </Typography>
          </Box>
          <CardMedia
            component="img"
            height="400"
            image="/mix-series2.jpg"
            alt="Mixová Série"
            sx={{ objectFit: "cover", borderRadius: 2 }}
          />
        </Box>

        <Box sx={{ marginTop: 4, width: "100%", maxWidth: "1200px" }}>
          <Typography variant="h4" component="div" gutterBottom>
            Tabulka s turnaji
          </Typography>
          <TableContainer component={Paper} sx={{ mb: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="body1">
                      <strong>Název</strong>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">
                      <strong>Datum</strong>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">
                      <strong>Kapacita</strong>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">
                      <strong>Detail</strong>
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tournaments.map((row, index) => {
                  const rowBgColor = index % 2 === 0 ? "#f9f9f9" : "#ffffff";
                  return (
                    new Date() < new Date(row.date) && (
                      <React.Fragment key={index}>
                        <TableRow
                          sx={{
                            cursor: "pointer",
                            backgroundColor: rowBgColor,
                            padding: "8px 0",
                          }}
                        >
                          <TableCell>
                            <Typography variant="body1">{row.name}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body1">
                              {new Intl.DateTimeFormat("cs-CZ", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                              }).format(new Date(row.date))}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body1">
                              {row.teams?.length || 0}/{row.capacity}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Button
                              variant="outlined"
                              onClick={() => handleExpandClick(index)}
                            >
                              Detail
                            </Button>
                          </TableCell>
                        </TableRow>

                        {/* Expanded detail section */}
                        <TableRow>
                          <TableCell
                            colSpan={5}
                            sx={{ padding: "8px", backgroundColor: rowBgColor }}
                          >
                            <Collapse in={openRows[index]}>
                              <Typography variant="h5" sx={{ mt: 1, ml: 1 }}>
                                <strong>{row.name}</strong>
                              </Typography>
                              <Box
                                sx={{
                                  backgroundColor: rowBgColor, // Matching background color
                                  padding: "20px",
                                  marginBottom: "20px",
                                  borderRadius: "8px",
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  sx={{
                                    mb: 1,
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <strong style={{ width: "200px" }}>
                                    Datum:
                                  </strong>
                                  <span style={{ marginLeft: "10px" }}>
                                    <Typography variant="body1">
                                      {new Intl.DateTimeFormat("cs-CZ", {
                                        day: "2-digit",
                                        month: "2-digit",
                                        year: "numeric",
                                      }).format(new Date(row.date))}
                                    </Typography>
                                  </span>
                                </Typography>
                                <Typography
                                  variant="body1"
                                  sx={{
                                    mb: 1,
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <strong style={{ width: "200px" }}>
                                    Areál:
                                  </strong>
                                  <span style={{ marginLeft: "10px" }}>
                                    {row.location}
                                  </span>
                                </Typography>
                                <Typography
                                  variant="body1"
                                  sx={{
                                    mb: 1,
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <strong style={{ width: "200px" }}>
                                    Přihlášky do:
                                  </strong>
                                  <span style={{ marginLeft: "10px" }}>
                                    {new Intl.DateTimeFormat("cs-CZ", {
                                      day: "2-digit",
                                      month: "2-digit",
                                      year: "numeric",
                                    }).format(
                                      new Date(row.registrationDeadline)
                                    )}{" "}
                                    23:59:59
                                  </span>
                                </Typography>
                                <Typography
                                  variant="body1"
                                  sx={{
                                    mb: 1,
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <strong style={{ width: "200px" }}>
                                    Odhlášky do:
                                  </strong>
                                  <span style={{ marginLeft: "10px" }}>
                                    {new Intl.DateTimeFormat("cs-CZ", {
                                      day: "2-digit",
                                      month: "2-digit",
                                      year: "numeric",
                                    }).format(
                                      new Date(row.logoutDeadline)
                                    )}{" "}
                                    23:59:59
                                  </span>
                                </Typography>

                                <Typography
                                  variant="body1"
                                  sx={{
                                    mb: 1,
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <strong style={{ width: "200px" }}>
                                    Startovné (za dvojici):
                                  </strong>
                                  <span style={{ marginLeft: "10px" }}>
                                    {new Intl.NumberFormat("cs-CZ").format(
                                      row.entryFee - 100
                                    )}
                                    ,- do{" "}
                                    {new Date(
                                      new Date(row.date).getTime() -
                                        14 * 24 * 60 * 60 * 1000
                                    ).toLocaleDateString("cs-CZ")}
                                  </span>
                                </Typography>

                                <Typography
                                  variant="body1"
                                  sx={{
                                    mb: 1,
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <strong style={{ width: "200px" }}></strong>
                                  <span style={{ marginLeft: "10px" }}>
                                    {new Intl.NumberFormat("cs-CZ").format(
                                      row.entryFee
                                    )}
                                    ,- do{" "}
                                    {new Date(
                                      new Date(row.date).getTime() -
                                        5 * 24 * 60 * 60 * 1000
                                    ).toLocaleDateString("cs-CZ")}
                                  </span>
                                </Typography>
                                <Typography
                                  variant="body1"
                                  sx={{
                                    mb: 1,
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <strong style={{ width: "200px" }}></strong>
                                  <span style={{ marginLeft: "10px" }}>
                                    {new Intl.NumberFormat("cs-CZ").format(
                                      Number(row.entryFee) + 100
                                    )}
                                    ,- od{" "}
                                    {new Date(
                                      new Date(row.date).getTime() -
                                        4 * 24 * 60 * 60 * 1000
                                    ).toLocaleDateString("cs-CZ")}
                                  </span>
                                </Typography>
                                <Typography
                                  variant="body1"
                                  sx={{
                                    mb: 1,
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <strong style={{ width: "200px" }}>
                                    Čas registrace:
                                  </strong>
                                  <span style={{ marginLeft: "10px" }}>
                                    9:30
                                  </span>
                                </Typography>
                                <Typography
                                  variant="body1"
                                  sx={{
                                    mb: 1,
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <strong style={{ width: "200px" }}>
                                    Organizátor:
                                  </strong>
                                  <span style={{ marginLeft: "10px" }}>
                                    {row.organizer}
                                  </span>
                                </Typography>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                  {row.additionalInfo}
                                </Typography>
                              </Box>

                              {/* Registered Teams */}
                              <Box
                                sx={{
                                  backgroundColor: rowBgColor, // Matching background color
                                  padding: "20px",
                                  marginBottom: "20px",
                                  borderRadius: "8px",
                                }}
                              >
                                <Typography variant="h5" gutterBottom>
                                  Přihlášené týmy:
                                </Typography>
                                <TableContainer component={Paper}>
                                  <Table>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>
                                          <Typography variant="body1">
                                            Nasazení
                                          </Typography>
                                        </TableCell>
                                        <TableCell>
                                          <Typography variant="body1">
                                            Hráč 1
                                          </Typography>
                                        </TableCell>
                                        <TableCell>
                                          <Typography variant="body1">
                                            Hráč 2
                                          </Typography>
                                        </TableCell>
                                        <TableCell>
                                          <Typography variant="body1">
                                            Body
                                          </Typography>
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {row.teams
                                        ?.slice(0, row.capacity)
                                        .map((team, idx) => {
                                          const player1 = users.find(
                                            (u) => u.id === team.player1
                                          );
                                          const player2 = users.find(
                                            (u) => u.id === team.player2
                                          );

                                          return (
                                            <TableRow key={idx}>
                                              <TableCell>
                                                <Typography variant="body1">{`${
                                                  idx + 1
                                                }.`}</Typography>
                                              </TableCell>
                                              <TableCell>
                                                <Link
                                                  to={
                                                    player1 &&
                                                    `/profile/${player1.id}`
                                                  }
                                                  style={{
                                                    textDecoration: "none",
                                                    color: "inherit",
                                                    display: "block",
                                                  }}
                                                >
                                                  {player1 &&
                                                    `${player1.displayName} ${player1.surname}`}
                                                </Link>
                                              </TableCell>
                                              <TableCell>
                                                <Link
                                                  to={
                                                    player2 &&
                                                    `/profile/${player2.id}`
                                                  }
                                                  style={{
                                                    textDecoration: "none",
                                                    color: "inherit",
                                                    display: "block",
                                                  }}
                                                >
                                                  {player2 &&
                                                    `${player2.displayName} ${player2.surname}`}
                                                </Link>
                                              </TableCell>
                                              <TableCell>
                                                <Typography variant="body1">
                                                  {team.teamPoints || 0}
                                                </Typography>
                                              </TableCell>
                                            </TableRow>
                                          );
                                        })}

                                      {/* Náhradníci Section */}
                                      {row.teams?.slice(row.capacity).length >
                                        0 && (
                                        <>
                                          <TableRow>
                                            <TableCell colSpan={4}>
                                              <Typography variant="h6">
                                                Náhradníci:
                                              </Typography>
                                            </TableCell>
                                          </TableRow>
                                          {row.teams
                                            ?.slice(row.capacity)
                                            .map((team, idx) => {
                                              const player1 = users.find(
                                                (u) => u.id === team.player1
                                              );
                                              const player2 = users.find(
                                                (u) => u.id === team.player2
                                              );

                                              return (
                                                <TableRow
                                                  key={idx + row.capacity}
                                                >
                                                  <TableCell>
                                                    <Typography variant="body1">{`${
                                                      row.capacity + idx + 1
                                                    }.`}</Typography>
                                                  </TableCell>
                                                  <TableCell>
                                                    <Link
                                                      to={
                                                        player1 &&
                                                        `/profile/${player1.id}`
                                                      }
                                                      style={{
                                                        textDecoration: "none",
                                                        color: "inherit",
                                                        display: "block",
                                                      }}
                                                    >
                                                      {player1 &&
                                                        `${player1.displayName} ${player1.surname}`}
                                                    </Link>
                                                  </TableCell>
                                                  <TableCell>
                                                    <Link
                                                      to={
                                                        player2 &&
                                                        `/profile/${player2.id}`
                                                      }
                                                      style={{
                                                        textDecoration: "none",
                                                        color: "inherit",
                                                        display: "block",
                                                      }}
                                                    >
                                                      {player2 &&
                                                        `${player2.displayName} ${player2.surname}`}
                                                    </Link>
                                                  </TableCell>
                                                  <TableCell>
                                                    <Typography variant="body1">
                                                      {team.teamPoints || 0}
                                                    </Typography>
                                                  </TableCell>
                                                </TableRow>
                                              );
                                            })}
                                        </>
                                      )}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Box>

                              {/* Registration Form */}
                              {user ? (
                                <Box
                                  sx={{
                                    backgroundColor: rowBgColor, // Matching background color
                                    padding: "20px",
                                    marginBottom: "20px",
                                    borderRadius: "8px",
                                  }}
                                >
                                  {new Date() >
                                    new Date(row.registrationDeadline) &&
                                  (!isUserInTournament(row.teams || []) ||
                                    new Date() >
                                      new Date(row.logoutDeadline)) ? (
                                    <Typography sx={{ color: "red" }}>
                                      Po deadlinu pro přihlášení/odhlášení
                                    </Typography>
                                  ) : (
                                    <>
                                      {!isUserInTournament(row.teams || []) && (
                                        <Typography
                                          variant="h5"
                                          sx={{ marginBottom: 2 }}
                                        >
                                          Přihlášení do turnaje
                                        </Typography>
                                      )}

                                      {/* Conditional Registration or Unregistration */}
                                      {isUserInTournament(row.teams || []) ? (
                                        <>
                                          {new Date() <=
                                          new Date(row.logoutDeadline) ? (
                                            row.teams?.map((team) => {
                                              if (
                                                team.player1 === user?.uid ||
                                                team.player2 === user?.uid
                                              ) {
                                                return (
                                                  <Button
                                                    key={team.player1} // Assuming player1 is unique for each team
                                                    variant="outlined"
                                                    color="secondary"
                                                    onClick={() =>
                                                      handleUnregisterTeam(
                                                        row.id,
                                                        team
                                                      )
                                                    }
                                                  >
                                                    Odhlásit z turnaje
                                                  </Button>
                                                );
                                              }
                                              return null;
                                            })
                                          ) : (
                                            <Typography sx={{ color: "red" }}>
                                              Odhlášení z turnaje již není možné
                                            </Typography>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {new Date() <=
                                            new Date(
                                              row.registrationDeadline
                                            ) && (
                                            <>
                                              <Autocomplete
                                                options={users.filter((u) => {
                                                  return (
                                                    u.id !== user?.uid &&
                                                    u.gender &&
                                                    user?.gender &&
                                                    u.gender.toLowerCase() !==
                                                      user?.gender.toLowerCase() &&
                                                    !row.teams?.some(
                                                      (team) =>
                                                        team.player1 === u.id ||
                                                        team.player2 === u.id
                                                    )
                                                  );
                                                })}
                                                getOptionLabel={(option) =>
                                                  `${option.displayName} ${option.surname} (${option.birthYear}, ${option.height} cm)`
                                                }
                                                value={
                                                  selectedUser[row.id] || null
                                                }
                                                onChange={(e, newValue) =>
                                                  setSelectedUser({
                                                    ...selectedUser,
                                                    [row.id]: newValue,
                                                  })
                                                }
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    label="Vyberte spoluhráče/čku"
                                                    variant="outlined"
                                                  />
                                                )}
                                                sx={{
                                                  width: "100%",
                                                  maxWidth: "400px",
                                                  mb: 2,
                                                }}
                                              />
                                              <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() =>
                                                  handleRegisterTeam(
                                                    row.id,
                                                    row.capacity,
                                                    row.teams || []
                                                  )
                                                }
                                              >
                                                Přihlásit tým
                                              </Button>
                                            </>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </Box>
                              ) : (
                                <Typography sx={{ ml: "25px", mb: 3 }}>
                                  Pro registraci do turnaje je nutné se{" "}
                                  <LinkMui href="/login">přihlásit</LinkMui>
                                </Typography>
                              )}
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    )
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
}

export default Mix;
