import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Avatar,
  Snackbar,
  Alert,
  Card,
  CardContent,
  Paper,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { storage, db, doc, getDoc, updateDoc, auth } from "../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { collection, getDocs } from "firebase/firestore";
import SaveIcon from "@mui/icons-material/Save";
import Navbar from "../components/Navbar";
import { onAuthStateChanged } from "firebase/auth";
import Footer from "../components/Footer";

function Profile() {
  const { uid } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [photoURL, setPhotoURL] = useState(user?.photoURL || "");
  const [displayName, setDisplayName] = useState(user?.displayName || "");
  const [surname, setSurname] = useState(user?.surname || "");
  const [height, setHeight] = useState(user?.height || "");
  const [weight, setWeight] = useState(user?.weight || "");
  const [birthYear, setBirthYear] = useState("");
  const [gender, setGender] = useState("");
  const [mixPoints, setMixPoints] = useState(0);
  const [kingQueenPoints, setKingQueenPoints] = useState(0);
  const [mixRank, setMixRank] = useState(0);
  const [kingQueenRank, setKingQueenRank] = useState(0);
  const [previewURL, setPreviewURL] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [tournaments, setTournaments] = useState({ mix: [], kingQueen: [] });

  useEffect(() => {
    const fetchPlayerRankings = async () => {
      const usersCollection = collection(db, "users");
      const usersSnapshot = await getDocs(usersCollection);

      const usersData = usersSnapshot.docs.map((doc) => ({
        id: doc.id,
        mixPoints: doc.data().mixPoints || 0,
        kingQueenPoints: doc.data().kingQueenPoints || 0,
      }));

      // Sort players by points in Mix and King/Queen
      const sortedMixPlayers = [...usersData].sort(
        (a, b) => b.mixPoints - a.mixPoints
      );
      const sortedKingQueenPlayers = [...usersData].sort(
        (a, b) => b.kingQueenPoints - a.kingQueenPoints
      );

      const currentPlayer = usersData.find((player) => player.id === uid);

      if (currentPlayer) {
        setMixPoints(currentPlayer.mixPoints);
        setKingQueenPoints(currentPlayer.kingQueenPoints);
        setMixRank(
          sortedMixPlayers.findIndex((player) => player.id === uid) + 1
        );
        setKingQueenRank(
          sortedKingQueenPlayers.findIndex((player) => player.id === uid) + 1
        );
      }
    };

    const fetchTournaments = async () => {
      const userRef = doc(db, "users", uid);
      const userSnapshot = await getDoc(userRef);
      const userData = userSnapshot.data();

      if (userData && userData.tournaments) {
        const mixTournaments = userData.tournaments.filter(
          (t) => t.category === "mix"
        );
        const kingQueenTournaments = userData.tournaments.filter(
          (t) => t.category === "king"
        );

        setTournaments({
          mix: mixTournaments,
          kingQueen: kingQueenTournaments,
        });
      }
    };

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setLoading(false);
      const userRef = doc(db, "users", uid);
      const docSnap = await getDoc(userRef);
      if (docSnap.exists()) {
        const userData = docSnap.data();
        setDisplayName(userData.displayName || "");
        setSurname(userData.surname || "");
        setHeight(userData.height || "");
        setWeight(userData.weight || "");
        setPhotoURL(userData.photoURL || user?.photoURL || "");
        setGender(userData.gender || "male");
        setBirthYear(userData.birthDate ? userData.birthDate : null);
      }
      fetchPlayerRankings();
      fetchTournaments();
    });

    return () => unsubscribe();
  }, [navigate, uid]);

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      const selectedFile = e.target.files[0];
      setFile(selectedFile);
      const previewURL = URL.createObjectURL(selectedFile);
      setPreviewURL(previewURL);
      setIsUploading(true);
    }
  };

  const handleUpload = async () => {
    if (file) {
      const storageRef = ref(storage, `profile_pics/${uid}`);
      try {
        await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);
        setPhotoURL(downloadURL);

        const userRef = doc(db, "users", uid);
        await updateDoc(userRef, { photoURL: downloadURL });

        setFile(null);
        setPreviewURL("");
        setIsUploading(false);
        setOpenSnackbar(true);
        setOpenDialog(false);
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  const handleSaveProfile = async () => {
    try {
      const userRef = doc(db, "users", uid);
      await updateDoc(userRef, { displayName, surname, height, weight });
      setIsEditing(false);
      setOpenSnackbar(true);
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Navbar />
      <Box
        sx={{
          backgroundColor: "#F5F5F5",
          color: "#333333",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "40px 20px",
        }}
      >
        <Card sx={{ maxWidth: 600, width: "100%", boxShadow: 3 }}>
          <CardContent sx={{ textAlign: "center" }}>
            {user && uid && user.uid === uid ? (
              <Avatar
                src={previewURL || photoURL}
                sx={{
                  width: 120,
                  height: 120,
                  margin: "0 auto 20px",
                  border: "4px solid #FF5722",
                  position: "relative",
                  "&:hover::before": {
                    content: '"Změnit fotku"',
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    color: "#FFFFFF",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                    fontSize: "16px",
                    fontWeight: "bold",
                  },
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={handleDialogOpen}
              />
            ) : (
              <Avatar
                src={previewURL || photoURL}
                sx={{
                  width: 120,
                  height: 120,
                  margin: "0 auto 20px",
                  border: "4px solid #FF5722",
                }}
              />
            )}

            <Typography
              variant="h4"
              component="h1"
              gutterBottom
              sx={{ color: "#FF5722" }}
            >
              {`${displayName} ${surname}` || "Profil"}
            </Typography>
            {/* Elegant Point and Ranking Display */}
            <Paper
              elevation={3}
              sx={{
                backgroundColor: "#FFF3E0",
                padding: "15px",
                textAlign: "center",
                marginBottom: "20px",
              }}
            >
              <Typography variant="h6" sx={{ color: "#333333", mb: 1 }}>
                Žebříček
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                <Box>
                  <Typography variant="body1" sx={{ color: "#FF5722" }}>
                    Mixy: {mixPoints} bodů (#{mixRank})
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body1" sx={{ color: "#FF5722" }}>
                    {gender && gender === "male" ? "King: " : "Queen: "}
                    {kingQueenPoints} bodů (#{kingQueenRank})
                  </Typography>
                </Box>
              </Box>
            </Paper>
            {/* Editable Info Section */}
            <Box sx={{ textAlign: "center", mb: 2 }}>
              {isEditing && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mb: 1,
                    }}
                  >
                    <Typography variant="body1">
                      <strong>Jméno:</strong>
                    </Typography>
                    <TextField
                      value={displayName}
                      onChange={(e) => setDisplayName(e.target.value)}
                      sx={{ width: "200px" }}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mb: 1,
                    }}
                  >
                    <Typography variant="body1">
                      <strong>Příjmení:</strong>
                    </Typography>
                    <TextField
                      value={surname}
                      onChange={(e) => setSurname(e.target.value)}
                      sx={{ width: "200px" }}
                    />
                  </Box>
                </>
              )}

              {!isEditing && birthYear && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 1,
                  }}
                >
                  <Typography variant="body1">
                    <strong>Ročník narození:</strong>
                  </Typography>
                  <Typography variant="body1">{`${birthYear} `}</Typography>
                </Box>
              )}

              {height && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 1,
                  }}
                >
                  <Typography variant="body1">
                    <strong>Výška:</strong>
                  </Typography>
                  {isEditing ? (
                    <TextField
                      value={height}
                      type="number"
                      onChange={(e) => setHeight(e.target.value)}
                      sx={{ width: "100px" }}
                    />
                  ) : (
                    <Typography variant="body1">{`${height} cm`}</Typography>
                  )}
                </Box>
              )}

              {weight && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 1,
                  }}
                >
                  <Typography variant="body1">
                    <strong>Hmotnost:</strong>
                  </Typography>
                  {isEditing ? (
                    <TextField
                      value={weight}
                      type="number"
                      onChange={(e) => setWeight(e.target.value)}
                      sx={{ width: "100px" }}
                    />
                  ) : (
                    <Typography variant="body1">{`${weight} kg`}</Typography>
                  )}
                </Box>
              )}
            </Box>
            {uid && user && user.uid === uid && !isEditing ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setIsEditing(true)}
                sx={{ mt: 2 }}
              >
                Upravit profil
              </Button>
            ) : isEditing ? (
              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveProfile}
                sx={{ mt: 2 }}
              >
                Uložit změny
              </Button>
            ) : null}
          </CardContent>
        </Card>

        <Dialog open={openDialog} onClose={handleDialogClose}>
          <DialogTitle>Nahrát profilový obrázek</DialogTitle>
          <DialogContent>
            <input type="file" accept="image/*" onChange={handleFileChange} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Zrušit</Button>
            <Button onClick={handleUpload} color="primary" disabled={!file}>
              Nahrát
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Změny byly úspěšně uloženy!
          </Alert>
        </Snackbar>

        {/* Tournament Results Section */}
        <Box
          sx={{
            width: "100%",
            maxWidth: 600, // Adjusting max width for consistency
            marginTop: 4,
            padding: 2,
            backgroundColor: "#FFFFFF",
            borderRadius: 2,
            boxShadow: 2,
          }}
        >
          <Typography
            variant="h5"
            sx={{ marginBottom: 2, color: "#FF5722", textAlign: "center" }}
          >
            Turnajové Výsledky
          </Typography>

          {/* Mix Tournaments Table */}
          <Typography variant="h6" sx={{ marginBottom: 1, color: "#FF5722" }}>
            Mixové Turnaje 2024/25
          </Typography>
          {tournaments.mix.length !== 0 ? (
            <Table
              sx={{ marginBottom: 4, tableLayout: "fixed", width: "100%" }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>Název</TableCell>
                  <TableCell>Datum</TableCell>
                  <TableCell>Umístění</TableCell>
                  <TableCell>Bodů</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tournaments.mix
                  .slice()
                  .sort((a, b) => b.points - a.points) // Sort by points in descending order
                  .map((tournament, index) => {
                    const isTopFour = index < 2; // Determine if it's one of the top two
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          backgroundColor: isTopFour
                            ? "transparent"
                            : "#F5F5F5", // Lighter background for non-top four
                          opacity: isTopFour ? 1 : 0.7, // Reduce visibility for others
                        }}
                      >
                        <TableCell>{tournament.tournamentName}</TableCell>
                        <TableCell>{tournament.date}</TableCell>
                        <TableCell>{tournament.place}</TableCell>
                        <TableCell>{tournament.points || 0}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          ) : (
            <Typography>
              Hráč zatím nemá odehraný žádný turnaj v této kategorii
            </Typography>
          )}

          {/* King/Queen Tournaments Table */}
          <Typography
            variant="h6"
            sx={{ marginTop: 4, marginBottom: 1, color: "#FF5722" }}
          >
            {gender && gender === "male" ? "King " : "Queen "}
            Turnaje 2024/25
          </Typography>
          {tournaments.kingQueen.length !== 0 ? (
            <Table sx={{ tableLayout: "fixed", width: "100%" }}>
              <TableHead>
                <TableRow>
                  <TableCell>Název</TableCell>
                  <TableCell>Datum</TableCell>
                  <TableCell>Umístění</TableCell>
                  <TableCell>Bodů</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tournaments.kingQueen
                  .slice()
                  .sort((a, b) => b.points - a.points) // Sort by points in descending order
                  .map((tournament, index) => {
                    const isTopFour = index < 2; // Determine if it's one of the top two
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          backgroundColor: isTopFour
                            ? "transparent"
                            : "#F5F5F5", // Lighter background for non-top four
                          opacity: isTopFour ? 1 : 0.7, // Reduce visibility for others
                        }}
                      >
                        <TableCell>{tournament.tournamentName}</TableCell>
                        <TableCell>{tournament.date}</TableCell>
                        <TableCell>{tournament.place}</TableCell>
                        <TableCell>{tournament.points || 0}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          ) : (
            <Typography>
              Hráč zatím nemá odehraný žádný turnaj v této kategorii
            </Typography>
          )}
        </Box>
      </Box>
      <Footer />
    </>
  );
}

export default Profile;
