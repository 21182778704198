import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Card, CardContent, CardMedia } from "@mui/material";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

function Homepage() {
  const navigate = useNavigate();
  const handleCardClick = (path) => {
    navigate(path);
  };

  return (
    <Box
      sx={{
        background: "linear-gradient(135deg, #F5F5F5 0%, #FFFFFF 100%)", // Gradient pozadí
        color: "#333333", // Tmavý text
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Navbar />
      <Box
        sx={{
          textAlign: "center",
          padding: "40px", // Zvýšení paddingu pro lepší vzhled
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h3"
          component="h1"
          gutterBottom
          sx={{
            color: "#FF5722",
            textShadow: "3px 3px 6px rgba(0, 0, 0, 0.3)", // Větší stín pro text
            fontWeight: "bold", // Tlustý text pro větší důraz
          }}
        >
          Zimní série na Pankráci
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "20px",
            marginTop: "30px",
            flexWrap: "wrap",
          }}
        >
          <Card
            sx={{
              maxWidth: 600,
              cursor: "pointer",
              backgroundColor: "#FFFFFF",
              color: "#333333",
              transition: "transform 0.3s, box-shadow 0.3s", // Efekty hover
              "&:hover": {
                transform: "scale(1.05)",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
              },
            }}
            onClick={() => handleCardClick("/mixova-serie")}
          >
            <CardMedia
              component="img"
              height="400"
              image="/mix-series2.jpg"
              alt="Mr. Mango mixy o Prize Money"
              sx={{
                objectFit: "cover",
                borderRadius: "8px", // Zaoblené rohy pro obrázky
              }}
            />
            <CardContent>
              <Typography variant="h5" component="div">
                Mr. Mango mixy o 10 000,-
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Přichází zima s ní je tady další série mixových turnajů o
                nějakou tu kačku. Přijď si zahrát kvalitní beach volejbal a
                shrábnout výhru, která čeká nejen na první 3 týmy!
              </Typography>
            </CardContent>
          </Card>

          <Card
            sx={{
              maxWidth: 600,
              cursor: "pointer",
              backgroundColor: "#FFFFFF",
              color: "#333333",
              transition: "transform 0.3s, box-shadow 0.3s", // Efekty hover
              "&:hover": {
                transform: "scale(1.05)",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
              },
            }}
            onClick={() => handleCardClick("/king-queen")}
          >
            <CardMedia
              component="img"
              height="400"
              image="/king-queen.jpg"
              alt="King/Queen of the Court"
              sx={{
                objectFit: "cover",
                borderRadius: "8px", // Zaoblené rohy pro obrázky
              }}
            />
            <CardContent>
              <Typography variant="h5" component="div">
                Open muži a ženy
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Klasický můžský/ženský open turnaj. Dorazit mohou svazoví i
                hobby hráči a čeká vás dostatek zápasů ať vyhráváte nebo
                prohráváte.
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
}

export default Homepage;
